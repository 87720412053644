export const WORDS = [
  'shell',
  'ocean',
  'coral',
  'fishy',
  'brain',
  'jumbo',
  'float',
  'solve',
  'sandy',
  'rocky',
  'whale',
  'shark',
  'guppy',
  'brine',
]
